<template>
  <div class="mt-6">
    <h1 class="panel-heading is-header">Supporters of the Santa Monica History Museum</h1>
    <div class="columns is-centered">
    <div class="column is-fullheight is-6">
      <button
      v-if="loggedIn"
        class="button is-link m-3 is-text is-sharp"
        style="text-decoration:none"
        v-on:click="addNew"
      >
        Add Supporter
      </button>

      <div class="mt-3">
      <div
        class="field"
        v-for="supporter in supporters.items"
        :key="supporter.id"
      >
        <div class="control has-icons-right">
          <input
            class="input has-text-centered is-text"
            :style="[!loggedIn ? { border: 'none' } : '']"
            type="text"
            maxlength="200"
            v-model="supporter.name"
            :readonly="!loggedIn"
            v-on:focus="this.setCurSupporter(supporter)"
            v-on:focusout="this.updateSupporter(supporter)"
          />

          <span class="icon is-small is-right" v-if="loggedIn">
            <button class="delete" v-on:click="remove(supporter)">
              Delete
            </button>
          </span>
        </div>
      </div>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      curSupporter: null,
      curName:""
    };
  },
  mounted() {
    this.$store.dispatch("supporters/getAll");
    //console.log(this.loggedIn)
  },
  computed: {
    supporters() {
      //return null
      var all = this.$store.state.supporters.all;
      return all;
    },
    loggedIn() {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    save: function () {
      //update all supporters
    },
    addNew: function () {
      //adds new supporter
      let data = {
        supporter: {
          name: "ADD SUPPORTER NAME HERE",
        },
      };

      this.$store.dispatch("supporters/create", data);
    },
    remove: function (supporter) {
     this.$store.commit("AWAIT_RESPONSE", true);

      this.$store.dispatch("supporters/destroy", supporter);
      //refresh the list and page
      this.$store.dispatch("supporters/getAll")
      .then(()=>{
           this.$store.commit("AWAIT_RESPONSE", false);
      });
    },
    updateSupporter(supporter) {
      if (this.curSupporter.id == supporter.id && 
      this.curName.localeCompare(supporter.name) != 0) {
        this.$store.commit("AWAIT_RESPONSE", true);
        this.$store.dispatch("supporters/update", supporter).then(()=>{
            this.$store.commit("AWAIT_RESPONSE", false);
        })
      }
    },
    setCurSupporter(supporter) {
      this.curSupporter = supporter;
      this.curName = supporter.name;
      //console.log("in: "+supporter.id)
    },
  },
};
</script>